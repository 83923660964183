import React from "react";
import "./Reset.css";
import { useState, useRef, useEffect } from "react";
import { NavLink, Link, useSearchParams, useNavigate } from "react-router-dom";
import { Fade } from "react-reveal";
import axios from "axios";
import { useAuthContext } from "../../../Hooks/useAuthContext";
import Success from "../../Success/Success";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";
import WhiteLoader from "../../Loaders/WhiteLoader";
const Reset = () => {
  const { api } = useAuthContext();
  const [pageUrl, setPageUrl] = useSearchParams();
  const navigate = useNavigate();

  const password = useRef();
  const confirmPassword = useRef();
  const [passType, setPassType] = useState("password");
  const [confirmPassType, setConfirmPassType] = useState("password");
  const [new_password1, setNewPass1] = useState("");
  const [new_password2, setNewPass2] = useState("");
  const [pass, setPass] = useState(true);
  const [confirmPass, setConfirmPass] = useState(true);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [checkReset, setCheckReset] = useState(false);
  const [show, setShow] = useState("Show");
  const [confirmShow, setConfirmShow] = useState("Show");

  const [resetPassData, setResetPassData] = useState(null);
  const [resetPassPending, setResetPassPending] = useState(false);
  const [resetPassSuccess, setResetPassSuccess] = useState("");
  const [resetPassError, setResetPassError] = useState("");

  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  const resetPassword = async (e) => {
    e.preventDefault();
    setResetPassPending(true);
    setResetPassSuccess("");
    setResetPassError("");
    const form = {
      new_password1,
      new_password2,
      uid: pageUrl.get("uid"),
      token: pageUrl.get("token"),
    };
    try {
      const response = await axios.post(
        `${api}/accounts/auth/password-reset/confirm/${pageUrl.get(
          "uid"
        )}/${pageUrl.get("token")}/`,
        form,
        config
      );
      if (response) {
        setResetPassPending(false);

        setResetPassData(response.data);
        setResetPassSuccess(response.data.Success);
        setResetPassError(response.data.Error);
        console.log(response);
        if (response.data.Success !== "") {
          navigate("/account");
        }
      }
    } catch (err) {
      if (err.code === 'ERR_NETWORK') {
        setResetPassError('Internet connection lost or disconnected');
        setResetPassPending(false)
      }  
      else if (err.code === 'ECONNABORTED') {
        setResetPassError('Request timeout , check internet connection');
        setResetPassPending(false)
      } else {
      
      setResetPassPending(false);
      setResetPassError(err.response.data.new_password2[0]);
      }
    }
  };

  const togglePass = () => {
    setPass(!pass);
    if (!pass) {
      setPassType("password");
      setShow("Show");
    }
    if (pass) {
      setPassType("text");
      setShow("Hide");
    }
  };
  const toggleConfirmPass = () => {
    setConfirmPass(!confirmPass);
    if (!confirmPass) {
      setConfirmPassType("password");
      setConfirmShow("Show");
    }
    if (confirmPass) {
      setConfirmPassType("text");
      setConfirmShow("Hide");
    }
  };

  const passwordKeyUp = () => {
    if (password.current.value.length < 8) {
      setPasswordError(true);
    } else {
      setPasswordError(false);
    }

    if (password.current.value === "") {
      setPasswordError(false);
    }
  };
  const confirmPasswordKeyUp = () => {
    if (confirmPassword.current.value === password.current.value) {
      setConfirmPasswordError(false);
    } else {
      setConfirmPasswordError(true);
    }

    if (confirmPassword.current.value === "") {
      setConfirmPasswordError(false);
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
  };

  const filled = () => {
    if (
      password.current.value !== "" &&
      confirmPassword.current.value !== "" &&
      !passwordError &&
      password.current.value === confirmPassword.current.value
    ) {
      setCheckReset(true);
    } else {
      setCheckReset(false);
    }
  };

  useEffect(() => {
    filled();
    if (checkReset) {
      console.log("true");
    } else {
      console.log("false");
    }
  }, [new_password1, new_password2]);
  return (
    <Fade duration={250}>
      <div className="reset-container">
        {resetPassData && resetPassSuccess !== "" && resetPassSuccess && (
          <Success message={resetPassSuccess} />
        )}
        {resetPassError !== "" && resetPassError && (
          <ErrorMessage message={resetPassError} />
        )}

        <div className="static-app-maintext">Reset Password</div>
        <div className="static-app-subtext">
          We advice you don’t share this with third parties
        </div>
        <form className="reset-form" onSubmit={resetPassword}>
          <div className="password-input-area">
            <div className="label-sign">
              <div className="label-sign-left">Password</div>
              <div className="label-sign-right">
                {passwordError && (
                  <p className="app-passError">
                    Password must be min. of 8 characters
                  </p>
                )}
              </div>

              {/* <div className="ShowLoginPass" onClick={togglePass}>
                {show}
              </div> */}
            </div>
            <input
              className="inputboxSignup"
              type={passType}
              name="password"
              placeholder="Min. of 8 characters"
              ref={password}
              value={new_password1}
              onChange={(e) => {
                setNewPass1(e.target.value);
              }}
              onKeyUp={() => {
                passwordKeyUp();
                confirmPasswordKeyUp();
              }}
              style={{
                background: passwordError ? "#FFF1F1" : "",
                borderColor: passwordError ? "#FF3737" : "",
              }}
            />

            <div className="label-sign">
              <div className="label-sign-left">Confirm Password</div>
              <div className="label-sign-right">
                {confirmPasswordError && (
                  <p className="app-passError">Password does not match</p>
                )}
              </div>

              {/* <div className="ShowLoginPass" onClick={toggleConfirmPass}>
                {confirmShow}
              </div> */}
            </div>
            <input
              className="inputboxSignup"
              type={confirmPassType}
              name="confirmPassword"
              placeholder="Min. of 8 characters"
              ref={confirmPassword}
              value={new_password2}
              onChange={(e) => {
                setNewPass2(e.target.value);
              }}
              onKeyUp={() => {
                passwordKeyUp();
                confirmPasswordKeyUp();
              }}
              style={{
                background: confirmPasswordError ? "#FFF1F1" : "",
                borderColor: confirmPasswordError ? "#FF3737" : "",
              }}
            />
          </div>
          {!resetPassPending && (
            <button
              type="submit"
              value="Next"
              className="SignUp-button"
              style={{
                backgroundColor: checkReset ? "#8F00FF" : "#F8F8F8",
                color: checkReset ? "#fff" : "#757575",
                cursor: checkReset ? "pointer" : "not-allowed",
              }}
              disabled={!checkReset}
            >
              Reset
            </button>
          )}
          {/* {!resetPassPending && !checkReset && (
            <button
              type="submit"
              value="Next"
              className="SignUp-button"
              style={{
                backgroundColor: "#F8F8F8",
                color: "#757575",
                cursor: "default",

                cursor: "not-allowed",
              }}
              disabled="true"
            >
              Reset
            </button>
          )} */}
          {resetPassPending && (
            <button
              type="submit"
              value="Next"
              className="SignUp-button"
              style={{
                backgroundColor: "#8F00FF",
                color: "#fff",
                cursor: "pointer",
                pointerEvents: "none",
                cursor: "pointer",
              }}
            >
              <WhiteLoader />
            </button>
          )}{" "}
        </form>
        <div className="forgot-to-login ">
          <Link to="/account/login" className="app-purple">
            <div>Return to Login</div>
          </Link>
        </div>
        <div className="reset-to-signup">
          Don’t have an account?{" "}
          <Link to="/account/signup" className="app-purple">
            Sign Up
          </Link>
        </div>
      </div>
    </Fade>
  );
};

export default Reset;
