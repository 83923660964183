import React from "react";
import { useAuthContext } from "../../../../Hooks/useAuthContext";
import DateComponent from "../../DateComponent/DateComponent";
import Hamburger from "../../Hamburger/Hamburger";
import { useState, useRef } from "react";
import "./HomeMobile.css";
import PopUp from "../../PopUp/PopUp";
import QevoltWarning from "../../../QevoltWarning/QevoltWarning";
import { useEffect } from "react";
import QevoltLabel from "../../../QevoltLabel/QevoltLabel";
import QevoltInput from "../../../QevoltInput/QevoltInput";
import QevoltButton from "../../../QevoltButton/QevoltButton";
import axios from "axios";
import Success from "../../../Success/Success";
import ErrorMessage from "../../../ErrorMessage/ErrorMessage";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import WhiteLoader from "../../../Loaders/WhiteLoader";
import PurpleLoader from "../../../Loaders/PurpleLoader";
const HomeMobile = ({
  data,
  pending,
  addModal,
  arr,
  removeitem,
  getOutletList,
  Odata,
  Opending,
  Oerror,
  getTransactions,
  getWhiteData,
  roll,
  getVirtualAccount,
  virtualpending,
}) => {
  const {
    businessData,
    pop,
    dispatch,
    transactionPop,
    filterPop,
    outletReset,
    api,
    token,
  } = useAuthContext();
  const reg = localStorage.getItem("reg");
  const outlet_secret_id = localStorage.getItem("outlet_secret_id");
  const [details, setDetails] = useState(null);
  const [outlet_name, setOutletName] = useState("");
  const [mail, setMail] = useState("");
  const [formPop, setFormPop] = useState(false);
  const [second, setSecond] = useState(false);
  const [password, setPassword] = useState("");
  const [cac, setCac] = useState(null);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [Updatepending, setPending] = useState(false);
  const showcopied = () => {
    setCopied(true);
  };

  const [copied, setCopied] = useState(false);
  const removeCopy = () => {
    setTimeout(() => {
      copy.current.classList.add("fade-out");
    }, 1500);
    setTimeout(() => {
      setCopied(false);
      copy.current.classList.remove("fade-out");
    }, 3000);
  };
  const upload = () => {
    document.querySelector(".upload").click();
  };
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  };
  const changeId = (
    secret,
    name,
    accNumber,
    bankName,
    verified,
    processed,
    access
  ) => {
    localStorage.setItem("outlet_secret_id", secret);
    localStorage.setItem("access", access);
    localStorage.setItem(
      "OutletDetails",
      JSON.stringify({
        name,
        accNumber,
        bankName,
        verified,
        processed,
      })
    );
    getTransactions();
    setDetails(JSON.parse(localStorage.getItem("OutletDetails")));
    dispatch({ type: "FILTER_POP_UP", payload: false });
    dispatch({ type: "BUSINESS_DATA", payload: null });
    dispatch({ type: "OUTLET_RESET", payload: true });
  };
  const [outletPending, setOutletPending] = useState(false);
  const [outletError, setOutletError] = useState("");
  const [outletSuccess, setOutletSuccess] = useState("");
  const [managersEmail, setManagersEmail] = useState("");

  const createOutlet = async () => {
    const business_secret_id = localStorage.getItem("business_secret_id");
    setOutletPending(true);
    try {
      const outletresponse = await axios.post(
        api + "/main/create/outlet",
        { business_secret_id, outlet_name },
        config
      );
      const virtualaccount = await axios.post(
        api + "/main/create/virtual_account",
        { outlet_secret_id: outletresponse.data.secret_id },
        config
      );

      if (virtualaccount) {
        const roleResponse = await axios.post(
          `${api}/main/create/roles`,
          {
            outlet_secret_id: outletresponse.data.secret_id,
            role: "MAGR",
            email: managersEmail,
          },
          config
        );
        if (roleResponse) {
          setOutletPending(false);
          setOutletError(virtualaccount.data.Error);
          setOutletSuccess("Outlet added. Return to home page to switch");
          setOutletName("");
        }
      }
    } catch (err) {
      setOutletPending(false);
      setOutletError("Unable to create a new outlet");
    } finally {
      setOutletPending(false);
    }
  };

  const refreshTransactions = async () => {};

  const role = localStorage.getItem("role");
  useEffect(() => {
    setDetails(JSON.parse(localStorage.getItem("OutletDetails")));
    dispatch({ type: "FILTER_POP_UP", payload: false });
    dispatch({ type: "POP_UP", payload: false });
    dispatch({ type: "TRANSACTION_POP_UP", payload: false });
  }, []);
  const copy = useRef();
  const navigate = useNavigate();

  const uploadCAC = (e) => {
    if (e.target.files[0]) {
      setCac(e.target.files[0]);
    }
  };

  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");

  const updateOutlet = async (event) => {
    event.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    setSuccess("");
    setError("");
    setPending(true);
    const business_id = localStorage.getItem("business_secret_id");
    try {
      const response = await axios.post(
        `${api}/main/upgrade/business`,
        { pkone: phone, address, cac, business_id },
        config
      );
      if (response && response.data.Success !== "") {
        setSuccess("Your business has been upgraded. Login to add outlets");
        setPending(false);
      }
      if (response && response.Error !== "") {
        setError(response.data.Error);
        setPending(false);
      }
    } catch (err) {
      setPending(false);
    } finally {
      setPending(false);
    }
  };
  const updateOutletReg = async (event) => {
    event.preventDefault();
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    };
    setSuccess("");
    setError("");
    setPending(true);
    const business_id = localStorage.getItem("business_secret_id");
    try {
      const response = await axios.post(
        `${api}/main/upgrade/business`,
        { phone, address, business_id },
        config
      );
      if (response && response.data.Success !== "") {
        setSuccess(
          "Business Graded Successfully please log in again to add outlets"
        );
        setPending(false);
      }
      if (response && response.Error !== "") {
        setError(response.data.Error);
        setPending(false);
      }
    } catch (err) {
      setPending(false);
    } finally {
      setPending(false);
    }
  };

  return (
    <>
      {!second && (
        <div className="mobile-container-transactions">
          {!formPop && (
            <>
              {pop && (
                <PopUp
                  style={{ height: "50vh" }}
                  heading={"Reach out to us"}
                  sub={"We’re available from 9AM - 5PM every weekday"}
                >
                  <div className="pop-body"></div>
                  <div
                    className="pop-body"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "35px",
                    }}
                  >
                    <p>
                      {" "}
                      <img src="/assets/cd.svg" alt="" /> Live chat
                    </p>
                    <p>
                      {" "}
                      <img src="/assets/c.svg" alt="" /> Call 01-513-5115
                    </p>
                    <p>
                      {" "}
                      <img src="/assets/m.svg" alt="" /> Email
                      support@qevolt.com
                    </p>
                  </div>
                </PopUp>
              )}
              <div className="" ref={copy}>
                {copied && <Success message="Copied to clipboard" />}
              </div>
              {filterPop && (
                <>
                  <PopUp
                    style={{ height: reg === "true" ? "35vh" : "38vh" }}
                    heading={"Switch Outlets"}
                    sub={""}
                  >
                    {reg === "true" && (
                      <>
                        <>
                          <div
                            className="pop-body"
                            style={{ marginBottom: "40px" }}
                          >
                            {Opending && (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <PurpleLoader />
                              </div>
                            )}

                            {!Opending &&
                              Odata &&
                              Odata.map((val, idx) => (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                  onClick={() =>
                                    changeId(
                                      val.outlet.secret_id,
                                      val.outlet.name,
                                      val.outlet.virtual_account.account_number,
                                      val.outlet.virtual_account.bank_name,
                                      val.outlet.virtual_account.total,
                                      val.outlet.total_transactions,
                                      val.outlet.default
                                    )
                                  }
                                >
                                  <div>
                                    <div
                                      style={{ display: "flex", gap: "6px" }}
                                      onClick={() =>
                                        changeId(
                                          val.outlet.secret_id,
                                          val.outlet.name,
                                          val.outlet.virtual_account
                                            .account_number,
                                          val.outlet.virtual_account.bank_name,
                                          val.outlet.total_transactions,
                                          val.outlet.virtual_account.total,
                                          val.outlet.default
                                        )
                                      }
                                    >
                                      <div className="name-circle">
                                        <p
                                          style={{
                                            marginTop: "0px",
                                            color: "white",
                                          }}
                                        >
                                          {" "}
                                          {val.outlet.name.substring(0, 1)}
                                        </p>
                                      </div>
                                      <p
                                        style={{
                                          transform: "translateY(-6px)",
                                        }}
                                      >
                                        {val.outlet.name}{" "}
                                      </p>
                                    </div>
                                    <p
                                      style={{
                                        marginLeft: "42px",
                                        transform: "translateY(-8px)",
                                        color: "#c4c4c4",
                                      }}
                                    >
                                      Outlet ID: {val.outlet.public_id}{" "}
                                    </p>
                                  </div>
                                  <div
                                    className="filter-radio"
                                    style={
                                      outlet_secret_id === val.outlet.secret_id
                                        ? {
                                            border: "1px solid #8F00FF",
                                            transform: "translateY(11px)",
                                          }
                                        : {
                                            border: "1px solid #c4c4c4",
                                            transform: "translateY(11px)",
                                          }
                                    }
                                  >
                                    <div
                                      className="filter-radio-circle"
                                      style={
                                        outlet_secret_id ===
                                        val.outlet.secret_id
                                          ? { background: "#8f00ff" }
                                          : { background: "none" }
                                      }
                                    ></div>
                                  </div>
                                </div>
                              ))}
                          </div>
                          <div
                            className="create-out"
                            onClick={() => {
                              setFormPop(true);
                              dispatch({
                                type: "FILTER_POP_UP",
                                payload: false,
                              });
                            }}
                          >
                            <img src="/assets/cc.svg" alt="" />
                            <p>Create a new outlet</p>
                          </div>
                        </>
                      </>
                    )}
                    {reg === "false" && (
                      <>
                        <div className="pop-body">
                          {Opending && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <PurpleLoader />
                            </div>
                          )}
                          {!Opending &&
                            Odata &&
                            Odata.map((val, idx) => (
                              <div>
                                <div style={{ display: "flex", gap: "6px" }}>
                                  <div className="name-circle">
                                    <p
                                      style={{
                                        marginTop: "0px",
                                        color: "white",
                                      }}
                                    >
                                      {" "}
                                      {val.outlet.name.substring(0, 1)}
                                    </p>
                                  </div>
                                  <p style={{ transform: "translateY(-5px)" }}>
                                    {val.outlet.name}{" "}
                                  </p>
                                </div>
                                <p
                                  style={{
                                    marginLeft: "42px",
                                    transform: "translateY(-8px)",
                                    color: "#c4c4c4",
                                  }}
                                >
                                  Outlet ID: {val.outlet.public_id}{" "}
                                </p>
                              </div>
                            ))}
                        </div>
                        <div onClick={() => setSecond(true)}>
                          <QevoltWarning
                            text={
                              "You’re unable to add an outlet because your business is unregistered. Tap to upgrade"
                            }
                          />
                        </div>
                      </>
                    )}
                  </PopUp>
                </>
              )}
              {transactionPop && (
                <PopUp
                  style={{ height: "45vh" }}
                  removeItem={removeitem}
                  heading={"Transaction Details"}
                  sub={""}
                >
                  {arr.map((arr, idx) => (
                    <>
                      <div
                        className="pop-body"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "13px",
                        }}
                      >
                        <p>Bank</p>
                        <h2
                          style={{
                            alignSelft: "center",
                            transform: "translateY(6px)",
                          }}
                        >
                          {arr.payer_bank_name}
                        </h2>
                      </div>
                      <div
                        className="pop-body"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "13px",
                        }}
                      >
                        <p>Name</p>
                        <h2
                          style={{
                            alignSelft: "center",
                            transform: "translateY(6px)",
                          }}
                        >
                          {arr.payer_account_name.toLowerCase()}
                        </h2>
                      </div>
                      <div
                        className="pop-body"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "13px",
                        }}
                      >
                        <p>Amount</p>
                        <h2
                          style={{
                            alignSelft: "center",
                            transform: "translateY(6px)",
                          }}
                        >
                          &#x20A6;{parseInt(arr.amount.toLocaleString())}
                        </h2>
                      </div>
                      <div
                        className="pop-body"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "13px",
                        }}
                      >
                        <p>Date and Time</p>
                        <h2
                          style={{
                            alignSelft: "center",
                            transform: "translateY(6px)",
                          }}
                        >
                          {new Date(arr.date_created)
                            .toDateString()
                            .substring(4)}{" "}
                          ,{" "}
                          {`${new Date(arr.date_created)
                            .toTimeString()
                            .substring(0, 2)} : ${new Date(arr.date_created)
                            .toTimeString()
                            .substring(3, 5)}  `}{" "}
                          {new Date(arr.date_created)
                            .toTimeString()
                            .substring(0, 2) > 12
                            ? "pm"
                            : "am"}
                        </h2>
                      </div>
                      <div
                        className="pop-body"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginBottom: "13px",
                        }}
                      >
                        <p>Transaction ID</p>
                        <h2
                          style={{
                            alignSelft: "center",
                            transform: "translateY(6px)",
                          }}
                        >
                          {arr.public_id.toLocaleString()}
                        </h2>
                      </div>
                    </>
                  ))}
                </PopUp>
              )}
              <div className="home-mobile-top">
                {businessData && !outletReset && role === "BOSS" && (
                  <div
                    className="home-mobile-top-left"
                    onClick={() => {
                      dispatch({ type: "FILTER_POP_UP", payload: true });
                      getOutletList();
                    }}
                  >
                    <div className="name-circle">
                      <p> {businessData?.name?.substring(0, 1)} </p>
                    </div>
                    <div className="business-name">
                      <p>{businessData?.name?.substring(0, 16)}... </p>
                      <img src="/assets/md.svg" alt="" />
                    </div>
                  </div>
                )}
                {businessData && !outletReset && role !== "BOSS" && (
                  <div className="home-mobile-top-left">
                  <div className="name-circle">
                      <p> {businessData?.outlet?.name?.substring(0, 1)} </p>
                    </div>
                    <div className="business-name">
                      <p>{businessData?.outlet?.name?.substring(0, 16)}... </p>
                    </div>
                  </div>
                )}
                {details && outletReset && role === "BOSS" && (
                  <div
                    className="home-mobile-top-left"
                    onClick={() => {
                      dispatch({ type: "FILTER_POP_UP", payload: true });
                      getOutletList();
                    }}
                  >
                    <div className="name-circle">
                      <p> {details?.name?.substring(0, 1)} </p>
                    </div>
                    <div className="business-name">
                      <p>{details?.name?.substring(0, 16)}... </p>
                      <img src="/assets/md.svg" alt="" />
                    </div>
                  </div>
                )}

                <div
                  className="home-mobile-top-right"
                  style={{ display: "flex", gap: "28.2px" }}
                >
                  <img
                    src="/assets/refreshh.svg"
                    alt=""
                    onClick={getWhiteData}
                    ref={roll}
                  />
                  <img
                    src="/assets/cd.svg"
                    alt=""
                    onClick={() => dispatch({ type: "POP_UP", payload: true })}
                  />
                </div>
              </div>

              {businessData && !outletReset && (
                <div className="home-mobile-middle">
                  <div
                    className="home-mobile-middle-box"
                    style={{
                      background: "#8f00ff",
                      border: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <h3 style={{ color: "#f4f4f4" }}>Account details</h3>
                      {businessData && (
                        <h2
                          style={{ color: "#fff", margin: "12px 0px 6px 0px" }}
                        >
                          {businessData.outlet.virtual_account.account_number}
                        </h2>
                      )}
                      {businessData && (
                        <p style={{ color: "#f4f4f4" }}>
                          {businessData.outlet.virtual_account.bank_name}
                        </p>
                      )}
                    </div>
                    {businessData &&
                      businessData.outlet.virtual_account.account_number !==
                        "None" &&
                      businessData.outlet.virtual_account.bank_name !==
                        "None" && (
                        <div className="dashboard-home-accountDetails-copy">
                          <CopyToClipboard
                            text={
                              businessData.outlet.virtual_account.account_number
                            }
                            onCopy={() => showcopied()}
                          >
                            <img
                              className="copy-icon"
                              src="/assets/copyy.svg"
                              alt=""
                              onClick={() => removeCopy()}
                              style={{ transform: "translateY(0px)" }}
                            />
                          </CopyToClipboard>
                        </div>
                      )}

                    {businessData &&
                      businessData.outlet.virtual_account.account_number ===
                        "None" &&
                      businessData.outlet.virtual_account.bank_name ===
                        "None" && (
                        <div
                          className="dashboard-home-accountDetails-copy"
                          onClick={() => getVirtualAccount()}
                        >
                          {!virtualpending && (
                            <img
                              style={{
                                width: "25px",
                                transform: "translateY(2px)",
                              }}
                              src="/assets/load.svg"
                            />
                          )}
                          {virtualpending && (
                            <img
                              style={{
                                width: "25px",
                                transform: "translateY(2px)",
                              }}
                              src="/assets/lo.svg"
                            />
                          )}
                        </div>
                      )}
                  </div>
                  <div className="home-mobile-middle-box">
                    {businessData && <h2>Transactions Verified</h2>}
                    {businessData && <p>{businessData.total_transactions}</p>}
                  </div>
                  <div className="home-mobile-middle-box">
                    {businessData && <h2>Amount Verified</h2>}
                    {businessData && (
                      <p>
                        &#x20A6;
                        {parseInt(businessData.total_amount).toLocaleString()}
                      </p>
                    )}
                  </div>
                </div>
              )}
              {details && outletReset && (
                <div className="home-mobile-middle">
                  <div
                    className="home-mobile-middle-box"
                    style={{
                      background: "#8f00ff",
                      border: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <h3 style={{ color: "#f4f4f4" }}>Account details</h3>
                      {details && (
                        <h2
                          style={{ color: "#fff", margin: "12px 0px 6px 0px" }}
                        >
                          {details.accNumber}
                        </h2>
                      )}
                      {details && (
                        <p style={{ color: "#f4f4f4" }}>{details.bankName}</p>
                      )}
                    </div>

                    {details && (
                      <div className="dashboard-home-accountDetails-copy">
                        <CopyToClipboard
                          text={details.accNumber}
                          onCopy={() => showcopied()}
                        >
                          <img
                            className="copy-icon"
                            src="/assets/copyy.svg"
                            alt=""
                            onClick={() => removeCopy()}
                            style={{ transform: "translateY(0px)" }}
                          />
                        </CopyToClipboard>
                      </div>
                    )}
                  </div>
                  <div className="home-mobile-middle-box">
                    {details && <h2>Transactions Verified</h2>}
                    {details && <p>{details.processed}</p>}
                  </div>
                  <div className="home-mobile-middle-box">
                    {details && <h2>Amount Verified</h2>}
                    {details && (
                      <p>
                        &#x20A6;
                        {details.verified}
                      </p>
                    )}
                  </div>
                </div>
              )}

              <div className="home-middle-close">
                <p>Recent transactions</p>
              </div>

              <div
                className="transactions-mobile-table-home"
                style={{ overflowY: "auto" }}
              >
                {pending && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <PurpleLoader />
                  </div>
                )}
                {data && data.length === 0 && !pending && (
                  <div
                    className="table-container"
                    style={{
                      display: "grid",
                      placeItems: "center",
                      height: "20vh",
                    }}
                  >
                    <p>No recent Transactions</p>
                  </div>
                )}
                {data &&
                  !pending &&
                  data
                    .map((val, idx) => (
                      <div className="table-container" key={idx}>
                        <div
                          className="table-flex"
                          onClick={() => {
                            addModal(val);
                            dispatch({
                              type: "TRANSACTION_POP_UP",
                              payload: true,
                            });
                            dispatch({ type: "POP_UP", payload: false });
                          }}
                        >
                          <div className="left-table">
                            <div className="left-table-circle">
                              <p style={{ color: "#757575", fontSize: "20px" }}>
                                {val.payer_account_name
                                  .toLocaleUpperCase()
                                  .substring(0, 1)}
                              </p>
                            </div>
                            <div className="left-table-details">
                              <h3 style={{ marginBottom: "6px" }}>
                                {val.payer_account_name
                                  .toLocaleLowerCase()
                                  .substring(0, 15)}
                                ...
                                {val.seen === "False" ? (
                                  <span
                                    style={{
                                      color: "white",
                                      transform: "translateX(20px)",
                                      backgroundColor: "#4BB543",
                                      padding: "2px 6px",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    New
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </h3>
                              <DateComponent
                                date_created={val.date_created}
                                seen={val.seen}
                              />
                            </div>
                          </div>
                          <div className="right-table">
                            <p>
                              {" "}
                              <p>
                                &#x20A6;{parseInt(val.amount).toLocaleString()}
                              </p>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                    .slice(0, 7)}
              </div>

              <Hamburger />
            </>
          )}

          {formPop && (
            <>
              {outletSuccess !== "" && outletSuccess && (
                <Success message={`${outletSuccess}`} />
              )}
              {outletError !== "" && outletError && (
                <ErrorMessage message={outletError} />
              )}
              <div className="home-mobile-top" style={{ marginBottom: "36px" }}>
                <div className="m-transactions-top-left">
                  <h1 style={{ fontSize: "18px" }}>
                    <img
                      src="/assets/arrow-left.svg"
                      alt=""
                      style={{
                        marginRight: "26px",
                        alignSelf: "center",
                        transform: "translateY(5px)",
                      }}
                      onClick={() => setFormPop(false)}
                    />{" "}
                    Create a new outlet
                  </h1>
                </div>
              </div>
              <QevoltWarning
                text={
                  "You’re about to add an outlet to your organization. You can monitor all activities of this outlet"
                }
              />

              <QevoltLabel text={"Outlet"} />
              <QevoltInput
                value={outlet_name}
                onChange={(e) => setOutletName(e.target.value)}
                name={"outlet_name"}
              />

              <QevoltLabel text={"Managers email (optional)"} />
              <QevoltInput
                value={managersEmail}
                onChange={(e) => setManagersEmail(e.target.value)}
                name={"email"}
              />
              {outlet_name !== "" && !outletPending && (
                <QevoltButton
                  text={"Create outlet"}
                  onClick={() => createOutlet()}
                />
              )}
              {outlet_name === "" && !outletPending && (
                <QevoltButton
                  text={"Create outlet"}
                  style={{ background: "#d9d9d9" }}
                />
              )}
              {outletPending && (
                <button className="q-button" style={{ background: "#d9d9d9" }}>
                 <WhiteLoader />
                </button>
              )}
            </>
          )}
        </div>
      )}
      <div style={{ width: "90%", margin: "70px auto" }}>
        {second && (
          <>
            {success !== "" && success && <Success message={`${success}`} />}
            {error !== "" && error && <ErrorMessage message={error} />}
            {reg === "false" && (
              <>
                <div
                  className="home-mobile-top"
                  style={{ marginBottom: "36px" }}
                >
                  <div className="m-transactions-top-left">
                    <h1 style={{ fontSize: "18px" }}>
                      <img
                        src="/assets/arrow-left.svg"
                        alt=""
                        style={{
                          marginRight: "26px",
                          alignSelf: "center",
                          transform: "translateY(5px)",
                        }}
                        onClick={() => setSecond(false)}
                      />{" "}
                      Outlet information
                    </h1>
                  </div>
                </div>
                <QevoltWarning text="Upload CAC certificate to upgrade to a registered business" />
                <form action="" onSubmit={updateOutlet}>
                  <QevoltLabel text={"Business name"} />
                  <QevoltInput
                    value={outlet_name}
                    onChange={(e) => setOutletName(e.target.value)}
                    name={"outlet_name"}
                  />
                  <QevoltLabel text={"Business phone number"} />
                  <QevoltInput
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    name={"phone"}
                  />
                  <QevoltLabel text={"Business Address"} />
                  <QevoltInput
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    name={"address"}
                  />
                  <QevoltLabel text={"CAC certificate"} />

                  <div className="form-group" onClick={upload}>
                    {
                      <input
                        type="file"
                        onChange={uploadCAC}
                        name="Upload"
                        className="upload"
                        accept="application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        style={{ display: "none" }}
                        required
                      ></input>
                    }
                    <div
                      className="kyc-dash-box"
                      style={{
                        backgroundImage: "none",
                        width: "100%",
                        border: "1px dashed #d9d9d9",
                        margin: "6px 0px 24px 0px",
                        height: "48px",
                        alignItems: "flex-start",
                        paddingLeft: "16px",
                        borderRadius: "10px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#757575",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        Upload file
                      </p>
                    </div>
                  </div>
                  {cac && (
                    <div
                      className=""
                      style={{
                        transform: "translateY(-15px)",
                        color: "green",
                        fontSize: "14px",
                      }}
                    >
                      Succesfully uploaded
                    </div>
                  )}

                  {!Updatepending && <QevoltButton text={"Update Outlet"} />}
                  {Updatepending && (
                    <button className="q-button" disabled>
                      <WhiteLoader />
                    </button>
                  )}
                </form>
              </>
            )}
            {reg === "true" && (
              <>
                <div
                  className="home-mobile-top"
                  style={{ marginBottom: "36px" }}
                >
                  <div className="m-transactions-top-left">
                    <h1 style={{ fontSize: "18px" }}>
                      <img
                        src="/assets/arrow-left.svg"
                        alt=""
                        style={{
                          marginRight: "26px",
                          alignSelf: "center",
                          transform: "translateY(5px)",
                        }}
                        onClick={() => setSecond(false)}
                      />{" "}
                      Outlet information
                    </h1>
                  </div>
                </div>
                <QevoltWarning text="Only business phone number and address can be edited on this form. Reach out to support to fully update outlet" />
                <form action="" onSubmit={updateOutletReg}>
                  <QevoltLabel text={"Business name"} />
                  <QevoltInput value={outlet_name} />
                  <QevoltLabel text={"Business phone number"} />
                  <QevoltInput
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    name={"phone"}
                  />
                  <QevoltLabel text={"Business Address"} />
                  <QevoltInput
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    name={"address"}
                  />
                  <QevoltLabel text={"CAC certificate"} />
                  <div className="form-group">
                    {/* {  <input type= "file" onChange={uploadCAC} name="Upload" className='upload' accept = "application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style={{display:'none'}} required></input>} */}
                    <div
                      className="kyc-dash-box"
                      style={{
                        backgroundImage: "none",
                        width: "100%",
                        border: "1px dashed #d9d9d9",
                        margin: "6px 0px 24px 0px",
                        height: "48px",
                        alignItems: "flex-start",
                        paddingLeft: "16px",
                        borderRadius: "10px",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "14px",
                          color: "#757575",
                          fontWeight: "500",
                        }}
                      >
                        {" "}
                        Certificate in place{" "}
                      </p>
                    </div>
                  </div>
                  {!pending && <QevoltButton text={"Update Outlet"} />}
                  {pending && (
                    <button className="q-button" disabled>
                      <WhiteLoader />
                    </button>
                  )}
                </form>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default HomeMobile;
